<template>
    <b-card no-body>
        <b-card-header>
            <b-card-title>
               Платежи
            </b-card-title>
        </b-card-header>
        <b-list-group v-if="dataSource.items.length > 0" flush class="mt-0 pt-0">
            <b-list-group-item v-for="item in dataSource.items" :key="item.id" class="d-flex align-items-center">
               <b-avatar
                    rounded
                    size="42"
                    :variant="item.type === 'btc' ? 'light-warning' : 'success'"
                    :text="item.type === 'btc' ? 'B' : '$'">
     
                </b-avatar>

                <div class="ml-1 flex-grow-1">
                    <div class="d-flex justify-content-between">
                        <div>
                            <span class="transaction-title cursor-pointer font-weight-bolder" v-b-tooltip.hover :title="item.identificator">
                                {{ start_and_end(item.identificator,9,9) }}
                            </span>
                        </div>
                        <div
                            class="font-weight-bolder"
                        >
                            {{ item.summ.formatMoney(0," "," ") }} $
                            <span v-if="item.refund_payment === 1" class="text-muted" style="font-size: 12px;">(поступило {{ (item.received * $blockchain.tickers.values.USD.last).formatMoney(0," "," ") }} $)</span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between" style="margin-top: 4px;">
                        <div class="text-muted">{{ Utils.lastActivityFormat(item.unix_timestamp) }}</div>
                        <b-badge v-if="item.done">
                            Исполнен
                        </b-badge>
                        <b-badge v-else-if="item.refund_payment === 1">
                            Возвращен
                        </b-badge>
                        <b-badge v-else-if="Math.floor(Date.now() / 1000) - item.unix_timestamp >= 3600">
                            Удалено без оплаты
                        </b-badge>
                        <b-badge v-else-if="item.received == 0 && item.unconfirmed == 0">
                            Не оплачен
                        </b-badge>
                        <b-badge v-else variant="light-primary">
                            В обработке
                        </b-badge>
                    </div>
                </div>     
            </b-list-group-item>
        </b-list-group>
        <b-card-body v-else class="text-center">
            <feather-icon icon="InfoIcon" size="52" class="text-danger" />
            <b-card-text class="text-muted mb-0 mt-1">
               Данные отсутствуют
            </b-card-text>
        </b-card-body>
    </b-card>
</template>

<script>
    
    import Utils from "@/modules/utils/"
    import User from "@/modules/user/"

    export default {

        data() {
            return {
                Utils,

                dataSource: {
                    items: [],
                    current_page: 1,
                    has_more_pages: false,
                    last_page: 1,
                    total_count: 0
                },   
            }
        },
        props: {
            user: Object
        },
        methods: {
            start_and_end(str, start = 10, end = 5) {
                if (str == null) return ''
                if (str.length > 20) {
                  return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },

             handleScroll( e ) {     
                                
                if(!this.dataSource.items) return;
                if(!this.dataSource.has_more_pages) return;
                if( this.is_loading ) return;
                
                let el = document.body;
                let doc = document.documentElement;
                let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
                               
                if( top + el.offsetHeight > ( el.scrollHeight - 100 ) ) {
                  
                  this.is_loading = true;
                  
                  this.$request.get("users/payments", {
                    id: this.$route.params.id,
                    page:  this.dataSource.current_page + 1
                    } ).then( result => {
                       this.dataSource.has_more_pages = result.has_more_pages;
                       this.dataSource.current_page = result.current_page;
                       this.dataSource.items.push( ...result.items );
                       this.is_loading = false;                       
                    });
                  
                }
                
            },

            get() {
                 this.$request.get("users/payments", {
                    id: this.$route.params.id
                 }).then(rsp => { 
                   this.is_loading = false;
                   this.dataSource.items = rsp.items;
                   this.dataSource.total_count = rsp.total_count;
                   this.dataSource.current_page = rsp.current_page;
                   this.dataSource.last_page = rsp.last_page;
                   this.dataSource.has_more_pages = rsp.has_more_pages
                });
            }
        },
        created() {
            window.addEventListener("scroll", this.handleScroll );
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll );
        },
        components: {

        },
        watch: {

        },
        mounted() {
            this.get()
        }

    }

</script>