<template>
    <b-card class="p-relative" no-body>
        
        <b-dropdown
            size="sm"
            right
            no-caret   
            variant="transparent"
            style="position: absolute; right: 16px; top: 8px;"
            class="group-picker"
        >
            <template #button-content>
                 <feather-icon icon="MoreHorizontalIcon" class="text-muted" size="22" />
            </template>
            <b-dropdown-item @click="$emit('onCompensation')" >
                Компенсация
            </b-dropdown-item>
            <b-dropdown-item @click="$emit('onEdit')" >
                Редактировать
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item v-if="!user.is_blocked" @click="$emit('blockUnblock')" >
               Заблокировать
            </b-dropdown-item>
            <b-dropdown-item v-else @click="$emit('blockUnblock')" >
               Разблокировать
            </b-dropdown-item>
        </b-dropdown>
        
        <b-card-body style="padding: 24px;" >
            <div class="d-flex flex-column align-items-center">
                <b-avatar
                    :variant="Users.utils.resolveAvatarColor( user.id )"
                    :text="Users.utils.capitalize(`${user.firstName}`, 1 )"
                    size="44px"
                />
                <div class="d-flex mt-1 text-center">
                    <div>
                      <h5 class="mb-0">
                        {{ user.firstName }} {{ user.lastName }}
                      </h5>
                      <div  style="font-size: 12px; opacity: 0.55;" >
                            {{ Users.utils.resolveUserGroupName(user.group) }}
                      </div>
                    </div>
                </div>
            </div>
                              
            
        </b-card-body>
        
        <div style="padding-left: 24px; padding-bottom: 24px;">
            <template v-if="user.personal">
                <div class="d-flex flex-column-reverse justify-content-between" >
                    <span class="text-capitalize font-small-2" style="opacity: 0.55;">
                        Страна
                    </span>
                    <b-card-text>{{ user.personal.country.name }}</b-card-text>
                </div>
                <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div>
            </template>
            
            <div class="d-flex flex-column-reverse justify-content-between" >
                <span class="text-capitalize font-small-2" style="opacity: 0.55;">
                    Статус
                </span>
                <b-card-text v-if="!user.is_blocked" class="m-0 ">Активен</b-card-text>
                <b-card-text v-else class="text-danger m-0">Заблокирован</b-card-text>
            </div>
            <template v-if='user.phone_number'>
                <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div>
                <div  class="d-flex flex-column-reverse justify-content-between" >
                    <span class="text-capitalize font-small-2" style="opacity: 0.55;">
                        Номер телефона
                    </span>
                    <b-link  class="text-ellipsis text-black">{{ user.phone_number }}</b-link>
                </div>
            </template>
            <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div>
            <div class="d-flex flex-column-reverse justify-content-between overflow-hidden" >
                <span class="text-capitalize font-small-2" style="opacity: 0.55;">
                    Почта 
                    <b-badge v-if="user.is_verify" variant="light-success" style="font-size: 7px; margin-left: 3px;">
                        Подтверждена
                    </b-badge>
                    <b-badge v-else variant="light-danger" style="font-size: 7px; margin-left: 3px;">
                        Не подтверждена
                    </b-badge>
                </span>
                <b-link :href="`mailto:${user.email}`" class="text-ellipsis ">{{ user.email }}</b-link>
            </div>
            <div class="delimiter" style="height: 1px; width: 100%; margin: 6px 0;"></div>
            <div class="d-flex flex-column-reverse justify-content-between" >        
                <span v-if="!user.is_referral" class=" font-small-2" style="opacity: 0.55;">
                    Выключена реферальная программа
                </span> 
                <span v-else class=" font-small-2" style="opacity: 0.55;">
                    Включена реферальная программа
                </span>            
                    <b-form-checkbox
                        v-model="user.is_referral"
                        class="mr-0 mb-50"
                        name="referral-checkbox"
                        switch
                        inline
                    />
            </div>
            
        </div>  
        
<!--        <hr class="mb-0" />
        
        <b-list-group class="no-hover" flush >
            <b-list-group-item>
                <div class="d-flex justify-content-between font-small-3">
                    <div class="text-muted">Статус</div>
                    <div v-if="user.is_verify" class="text-success">Активен</div>
                    <div v-else class="text-danger">Не активен</div>
                </div>
            </b-list-group-item>
            <b-list-group-item>
                <div class="d-flex justify-content-between font-small-3">
                    <div class="text-muted">Роль</div>
                    <div>
                        <feather-icon 
                            :icon="Users.utils.resolveUserGroupIcon(user.group)" 
                            :class="Users.utils.resolveUserGroupIconColor(user.group)" 
                            size="18"  
                        />
                        {{ Users.utils.resolveUserGroupName(user.group) }}
                    </div>
                </div>
            </b-list-group-item>
        </b-list-group>-->
        
    </b-card>
</template>

<script>

    import Users from "@/modules/admin/users/"

    export default {
        data() {
            return {
                Users
            }
        },
        props: {
            user: Object
        },
        methods: {
            toUSD( btc ) {
                return btc * this.$blockchain.tickers.values.USD.last;
            },
        },
        components: {

        },
        watch: {
            user: {
                deep: true,
                handler(bef) {
                    this.$emit('update');
                }
            }
        },
        mounted() {

        }

    }

</script>