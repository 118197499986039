<template>
    <div>
        
        <b-card v-if="error.active" no-body bg-variant="transparent">
            <b-alert
                variant="danger"
                show
                class="m-0"
            >
                <div class="alert-heading">
                  {{ error.access.module.name }}
                </div>
                <div class="alert-body">
                    {{ error.info }} 
                </div>
            </b-alert>
        </b-card>
        
        <b-card v-else no-body>
            <b-card-header>
                <b-card-title>Контракты</b-card-title>
                <b-button-group>
                    <b-button @click='t = 1' :variant="t === 1 ? 'primary' : 'light' " size="sm">Облако</b-button>
                    <b-button @click='t = 2' :variant="t === 2 ? 'primary' : 'light'" size="sm">Майнеры</b-button>
                </b-button-group>
            </b-card-header>
            <b-card-body style="padding: 0 24px 24px 24px;">
                <b-button-group>
                    <template v-for="(status, index) in statuses">
                        <b-button  @click="setStatus(status.value)" :variant="s === status.value ? 'active' : 'inactive' " size="sm" style="height: 36px;">{{ status.display }}</b-button>
                        <div v-if="index < statuses.length - 1" class="delimiter"></div>
                    </template>
                </b-button-group> 
            </b-card-body>
           
            
            <b-skeleton-table v-if='is_loading' animation="fade" :columns='5' :rows='5'></b-skeleton-table>

            <b-card-body class="text-center mt-0 pt-0" v-else-if='dataSource.items.length === 0' >
                <feather-icon icon="InfoIcon" size="52" class="text-danger" />
                <b-card-text class="text-muted mb-0 mt-1">
                   Данные отсутствуют
                </b-card-text>
            </b-card-body>
            
            <b-table-simple v-else responsive >
                <b-thead>
                    <b-tr>
                        <b-th>№ контракта</b-th>
                        <b-th>Добыто чистыми</b-th>
                        <b-th v-if="t === 1">Хешрейт</b-th>
                        <b-th v-else>Хешрейт</b-th>
                        <b-th v-if="t === 2">Майнеры</b-th>
                        <b-th>Статус</b-th>
                        <b-th>Инвестировано</b-th>
                    </b-tr>
                </b-thead>

                <b-tbody>
                    <b-tr v-for="(item, index) in dataSource.items" v-ripple class='cursor-pointer' :key="`contract:${item.id}:${index}`">
                        <b-th>
                            <b-link :to="{ name: 'admin-contracts-view', params: { id: item.guuid } }"> {{ item.guuid }}</b-link>
                        </b-th>
                        <b-th class="font-weight-normal text-black">{{ getTotalProfit(item.current_balance) }} <span class="text-muted">{{ ticker }}</span> </b-th>
                        <b-th class="font-weight-normal text-black">{{ item.ths.formatMoney(2,' ', ' ') }} <span class="text-muted">TH/s</span> </b-th>
                        <b-th v-if="t === 2" class="font-weight-normal text-black">{{ item.miners_count }} <span class="text-muted">шт.</span> </b-th>
                        <b-th class="font-weight-normal text-black"> 
                            <b-badge v-if="item.status == 1">Работает</b-badge>
                            <b-badge v-else-if="item.status == 2" variant="primary">Оплата получена</b-badge>
                            <b-badge v-else-if="item.status == 3" variant="primary">Старт {{ Utils.daysAgo( item.start_at ) }}</b-badge>
                            <b-badge v-else-if="item.status == 0" variant="danger">Требуется оплата</b-badge>
                            <b-badge v-else-if="item.status == 5" variant="danger">Не оплачен</b-badge>
                            <b-badge v-else-if="item.status == 6" variant="danger">В архиве</b-badge>
                            <b-badge v-else-if="item.status == 7" variant="danger">Приостановлен</b-badge>
                            <b-badge v-else variant="success">Завершён</b-badge>
                        </b-th>
                        <b-th v-if="item.invoice" class="font-weight-normal text-black">
                            {{ item.invoice.btc.formatMoney(8,' ', ' ') }} <span class="text-muted">BTC</span>
                        </b-th>
                         <b-th v-else class="font-weight-normal text-black">
                            {{ convertUSDtoBTC(item.investments).formatMoney(8,' ', ' ') }} <span class="text-muted">BTC</span>
                        </b-th>
               
                    </b-tr>
                </b-tbody>

            </b-table-simple>
            
<!--            <b-card-body v-if="statistic.total_count > 0" class="p-0 d-flex">
                <div class="ml-auto mr-auto">
                     <b-pagination-nav :value="statistic.current_page" :link-gen="linkGen" :number-of-pages="statistic.last_page" use-router replace ></b-pagination-nav>
                </div>
            </b-card-body>-->
            
        </b-card>
    </div>
</template>

<script>

    import User from "@/modules/user/"
    import Utils from "@/modules/utils/"
    export default {

        data() {
            return {
                
                Utils,
                
                dataSource: {
                    items: []
                },
                
                t: 1,
                s: null,
                
                is_loading: false,
                
                statuses: [
                    { value: null, display: "Все" },
                    { value: "inwork", display: "В работе" },
                    { value: "nopay", display: "Неоплаченные" },
                    { value: "complete", display: "Выполненые" }
                ],
                
                error: {
                    active: false,
                    access: null,
                    code: -1,
                    message: null,
                    info: null,
                },
            }
        },
        methods: {
            
            convertUSDtoBTC( value ) {
                return ( value / this.$blockchain.tickers.values['USD'].last );
            },
            
            getCompletePercent( contract ) {
                if( this.t === 2 ) {
                    // let percent = ( (Math.floor(Date.now() / 1000)) / contract.end_at );
                    // return  100 - (percent * 100) ;

                    return 100 * ((Math.floor(Date.now() / 1000)) - contract.start_at) / (contract.end_at - contract.start_at)
                }
                return ((contract.total_balance * this.$blockchain.tickers.values.USD.last ) / this.getPeriodProfitUSD( contract )) * 100;     
            },
            
            getPeriodProfitUSD( contract ) {
                return (((84600 * 30 * contract.period * User.contracts.values.R * ( User.contracts.values.H * contract.ths )) / ( User.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values["USD"].last) ;                
            },

            getTotalProfit( value ) {
                return ( value * this.$blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
            },
            
            setStatus( value ) {
                this.s = value;
            },
            get() {
                this.is_loading = true;
                this.$request.get("contracts/getByUser", this.params ).then( rsp => {
                    this.is_loading = false;
                    this.dataSource.items = rsp.items;
                }).catch(err => {
                    this.is_loading = false;
                    this.error.active = true;
                    this.error.code = err.code;
                    this.error.message = err.message;
                    this.error.info = err.info;
                    this.error.access = err.access;
                });
            },
        },
        components: {

        },
        computed: {
            ticker() {
                return User.getSymbol()
            },
            params() {
                return {
                    type: this.t,
                    status: this.s,
                    user: this.$route.params.id
                }
            },
        },
        watch: {
            "s" : function() {
                this.get();
            },
            "t" : function() {
                this.get();
            },
        },
        mounted() {
            this.get();
        }

    }

</script>