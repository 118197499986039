<template>
    
    
    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      variant='transparent'
      style="min-height: 50vh;"
    >
        
        <b-row v-if="user" class="d-flex">
        
            <b-col  md='3' lg='3' cols='12' >
                
                <user-card-view :user="user" @onEdit="preferEditAction" @update="edit" @onCompensation="preferCompensationAction" @blockUnblock="blockUnblock" />
                <user-wallet-view :user="user" />
                <user-miners-view :user="user" />
                
            </b-col >

            <b-col  md='9' lg='9' cols='12'>
                <user-permissions-table v-if="user.group > 1 && current.group === 5" :user="user" />
                <user-contracts-table />
                <b-row>
                    <b-col md="6">
                        <user-transactions-view :user="user" />
                    </b-col>
                    <b-col md="6">
                        <user-payments-view :user="user" />
                    </b-col>
                </b-row>
            </b-col >

        </b-row>
        
        <b-modal
            ref="editModal"
            title="Редактировать"
            centered
            hide-footer
        >
             <validation-observer ref="form">    
                <b-form>
                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }" rules="required">
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Имя пользователя</span>                             
                            <b-form-input
                              v-model="form.firstName"
                            />
                        </validation-provider>
                    </b-form-group>
                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }">
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Фамилия пользователя</span>                             
                            <b-form-input
                              v-model="form.lastName"
                            />
                        </validation-provider>
                    </b-form-group>
                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }" rules="required">
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Роль в системе</span>                             
                            <b-form-select v-model="form.group" class="form-control">
                                <b-form-select-option value="1">
                                    Пользователь
                                </b-form-select-option>
                                <b-form-select-option value="2">
                                    Редактор
                                </b-form-select-option>
                                <b-form-select-option value="3">
                                    Менеджер
                                </b-form-select-option>
                                <b-form-select-option value="4">
                                    Агент поддержки
                                </b-form-select-option>
                                <b-form-select-option value="5">
                                    Администратор
                                </b-form-select-option>
                            </b-form-select>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }" rules="required|email">
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Электронная почта</span>                             
                            <b-form-input
                              v-model="form.email"
                              :disabled="user.group === 1"
                            />
                        </validation-provider>
                    </b-form-group>
                    
                    <div class="mt-2 d-flex justify-content-end">
                        <b-button @click="edit" variant="primary">
                            Сохранить
                        </b-button>
                    </div>
                    
                </b-form>
             </validation-observer>
            
        </b-modal>


        <b-modal
            ref="compensationModal"
            title="Компенсация пользователю"
            centered
            hide-footer
        >
             <validation-observer ref="formCompensation">    
                <b-form>
                    <div class="d-flex">
                        <p> Денежные средства поступят на расчетный счет пользователя. </p>
                    </div>
                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }">
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Фамилия пользователя</span>                             
                            <b-form-input
                                disabled
                                v-model="user.lastName"
                            />
                        </validation-provider>
                    </b-form-group>
                    <b-form-group class="form-group-with-label">
                        <validation-provider #default="{ errors }">
                            <span :class="{
                                'text-muted' : errors.length > 0 ? false : true,
                                'font-weight-bolder': errors.length > 0 ? true : false,
                                'text-danger' : errors.length > 0 ? true : false
                            }" style="font-size: 12px;">Имя пользователя</span>                             
                            <b-form-input
                                disabled
                                v-model="user.firstName"
                            />
                        </validation-provider>
                    </b-form-group>
                    <b-form-group class="form-group-with-label">
                       <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                            <div style="flex-grow: 1;">
                                
                                <validation-provider
                                    #default="{ errors }"
                                    name="Compensation"
                                    rules="required"
                                >
                                    <div v-if='errors[0]' class='text-danger'>
                                        <span  style="font-size: 12px; font-weight: 500;">
                                            Сумма компенсации
                                        </span>
                                        <feather-icon icon='AlertCircleIcon' />
                                    </div>
                                    <span v-else class='text-muted' style="font-size: 12px;">Сумма компенсации</span> 
                                    
                                    <b-form-input
                                        type="number"
                                        placeholder="Сумма компенсации"
                                        v-model.number="compensation"
                                    />
                                        <!-- :formatter="summFormatter" -->
                                </validation-provider>
                                
                            </div>
                            <div class="mt-auto mb-auto">
                                <b-dropdown
                                    :text="ticker"
                                    variant="muted"
                                    size="sm"
                                    right
                                  >
                                    
                                    <b-dropdown-item @click="changeCurrencyType('USD')">USD</b-dropdown-item>
                                    <b-dropdown-item @click="changeCurrencyType('BTC')">BTC</b-dropdown-item>

                                </b-dropdown>
                            </div>
                        </div>
                    </b-form-group>
                    
                    <div class="mt-2 d-flex justify-content-end">
                        <b-button @click="compensationUser" :disabled="is_save_compensation" variant="primary">
                            Зачислить
                        </b-button>
                    </div>
                    
                </b-form>
             </validation-observer>
            
        </b-modal>
        
    </b-overlay>
 
</template>

<script>

    import User from "@/modules/user/"
    import Users from "@/modules/admin/users/"
    
    import UserContractsTable from "./widgets/UserContractsTable"
    import UserWalletView from "./widgets/UserWalletView"
    import UserCardView from "./widgets/UserCardView"
    import UserTransactionsView from "./widgets/UserTransactionsView"
    import UserPaymentsView from "./widgets/UserPaymentsView"
    import UserMinersView from "./widgets/UserMinersView"
    import UserPermissionsTable from "./widgets/UserPermissionsTable"
    
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'

    export default {

        data() {
            return {
                Users,
                current: User.self,
                is_loading: false,
                
                required,
                email,
                
                error: {
                    active: false,
                    code: -1,
                    message: null,
                    info: null,
                },
                user: null,
                compensation: 0,
                form: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    group: null,
                    email: null
                },

                ticker: 'USD',
                is_save_compensation: false
            }
        },
        beforeRouteEnter(from, to, next ) {
            
            let perms = User.self.permissions.find(item => item.registry.key === 'users' );
                        
            if( !perms ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'users'
                    }
                });
            }
            
            if( perms.read === false ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'users'
                    }
                });
            }
            
            next( true );
            
        },
        methods: {
            preferEditAction( args ) {
                this.$refs.editModal.show();
            },
            preferCompensationAction( args ) {
                this.$refs.compensationModal.show();
            },
            toUSD( btc ) {
                return btc * this.$blockchain.tickers.values.USD.last;
            },
            get() {
                this.is_loading = true;
                this.$request.get("users/get", {
                    id: this.$route.params.id
                }).then( rsp => {
                    this.user = rsp;
                    this.is_loading = false;
                    Object.keys( this.form ).forEach(key => {
                        if( rsp.hasOwnProperty(key) ) {
                            this.form[key] = rsp[key];
                        }
                    });
                }).catch(err => {
                    this.is_loading = false;
                    this.error.active = true;
                    this.error.code = err.code;
                    this.error.message = err.message;
                    this.error.info = err.info;
                });
            },
            edit() {
                 let params = this.form;
                 params['user'] = this.user.id;
                 params['action'] = 'profile';
                 params['is_referral'] = this.user.is_referral;
                 this.$request.post("users/update", params ).then( rsp => {
                     this.$refs.editModal.hide();
                     Object.keys( this.form ).forEach( key => {
                         this.user[key] = this.form[key];
                     })
                 });
            },
            compensationUser() {
                this.is_save_compensation = true;
                this.$request.post("users/compensation", {
                    id: this.user.id,
                    ticker: this.ticker,
                    compensation: this.compensation
                } ).then( rsp => {
                    this.$refs.compensationModal.hide();
                    this.is_save_compensation = false;   
                    this.compensation = 0;                 
                });
            },
            changeCurrencyType( value ) {
                
                if( this.ticker === value ) return;
                
                this.ticker = value;
                switch( value ) {
                    
                    case 'USD' : 
                        
                        this.compensation = this.compensation * this.$blockchain.tickers.values.USD.last;
                        
                        this.compensation = this.compensation.toFixed(0);
                        
                        break;
                        
                        
                    case 'BTC' : 
                        
                        this.compensation = this.compensation / this.$blockchain.tickers.values.USD.last;
                    
                        break;
                                                    
                }
                                
            },

            blockUnblock() {
                this.$request.post("users/updateSettings", {
                    user: this.user.id,
                    key: 'is_blocked',
                    value: !this.user.is_blocked
                }).then( rsp => {
                    this.get();
                });
            }
        },
        components: {
            UserCardView,
            UserWalletView,
            UserContractsTable,
            UserTransactionsView,
            UserPaymentsView,
            UserMinersView,
            UserPermissionsTable,
            ValidationProvider,
            ValidationObserver
        },
        watch: {
        },
        mounted() {
           this.get();
        }

    }

</script>