<template>
    <b-card no-body>
        <b-card-header>
            <b-card-title>
               Майнеры
            </b-card-title>
        </b-card-header>
        <b-list-group v-if="user.miners.length > 0" flush class="mt-0 pt-0">
            <b-list-group-item v-for="item in user.miners" :key="item.id" class="d-flex align-items-center">
               <b-avatar
                    rounded
                    size="42"
                    :src="item.machine.medias[0].urls.thumb"
                >
     
                </b-avatar>

                <div class="ml-1 flex-grow-1">
                    <div class="d-flex justify-content-between">
                        <div class="font-small-2">
                            <span class="transaction-title cursor-pointer font-weight-bolder">
                               {{ item.machine.name }}
                            </span>
                            <div >
                               {{ item.serial_number }}
                            </div>
                        </div>
                       
                    </div>
<!--                    <div class="d-flex justify-content-between" style="margin-top: 4px;">
                        <div class="text-muted">{{ Utils.lastActivityFormat(item.unix_timestamp) }}</div>
                        <b-badge v-if="item.done">
                            Исполнен
                        </b-badge>
                        <b-badge v-else variant="light-primary" >
                            В обработке
                        </b-badge>
                    </div>-->
                </div>     
            </b-list-group-item>
        </b-list-group>
        <b-card-body v-else class="text-center">
            <feather-icon icon="InfoIcon" size="52" class="text-danger" />
            <b-card-text class="text-muted mb-0 mt-1">
               Данные отсутствуют
            </b-card-text>
        </b-card-body>
    </b-card>
</template>

<script>
    
    import Utils from "@/modules/utils/"

    export default {

        data() {
            return {
                Utils
            }
        },
        props: {
            user: Object
        },
        methods: {
            start_and_end(str, start = 10, end = 5) {
                if (str.length > 20) {
                  return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>