<template>
    <div>
        
        <b-card no-body>
            
            <b-card-header>
                <b-card-title>
                    Права доступа
                </b-card-title>
            </b-card-header>
            
            <b-table-simple class="table-striped">
                <b-thead>
                    <b-tr>
                        <b-td class="text-black font-weight-bolder">Модуль/Права</b-td>
                        <b-td class="text-black font-weight-bolder">Чтение</b-td>
                        <b-td class="text-black font-weight-bolder">Запись</b-td>
                        <b-td class="text-black font-weight-bolder">Ред.</b-td>
                        <b-td class="text-black font-weight-bolder">Удаление</b-td>
                        <b-td></b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-if="user.permissions.length === 0">
                        <b-td colspan="6" class="text-center">
                            <span class="text-muted">Нет активных модулей</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-for="(perm, index) in user.permissions">
                        <b-td class="text-black">{{ perm.registry.name }}</b-td>
                        <b-td>
                            <b-form-checkbox
                                :checked="perm.read"
                                @change=" setperms($event, perm.registry.id, 'read') "
                                class="custom-control-success"
                                switch
                                inline
                                :disabled="isOnModuleAddState"
                            />
                        </b-td>
                        <b-td>
                            <b-form-checkbox
                                :checked="perm.write"
                                @change=" setperms($event, perm.registry.id, 'write') "
                                class="custom-control-success"
                                switch
                                inline
                                :disabled="isOnModuleAddState"
                            />
                        </b-td>
                        <b-td>
                            <b-form-checkbox
                                :checked="perm.edit"
                                @change=" setperms($event, perm.registry.id, 'edit') "
                                class="custom-control-success"
                                switch
                                inline
                                :disabled="isOnModuleAddState"
                            />
                        </b-td>
                        <b-td>
                            <b-form-checkbox
                                @change=" setperms($event, perm.registry.id, 'delete') "
                                class="custom-control-danger"
                                :checked="perm.delete"
                                switch
                                inline
                                :disabled="isOnModuleAddState"
                            />
                        </b-td>
                        <b-td class="text-center">
                            <b-link @click='delperms(perm.registry.id, index)' v-b-tooltip.hover title="Удалить" >
                                <feather-icon icon="TrashIcon" class="text-danger" size="22" />
                            </b-link>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="isOnModuleAddState">
                        <b-td>
                            <b-form-select v-model="form.module">
                                 <b-form-select-option :value="null">Выбрать модуль</b-form-select-option>
                                <b-form-select-option v-for="module in modules" :value="module.id">
                                    {{ module.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-td>
                        <b-td>
                            <b-form-checkbox
                                v-model="form.read"
                                class="custom-control-success"
                                switch
                                inline
                            />
                        </b-td>
                        <b-td>
                            <b-form-checkbox
                                v-model="form.write"
                                class="custom-control-success"
                                switch
                                inline
                            />
                        </b-td>
                        <b-td>
                            <b-form-checkbox
                                 v-model="form.edit"
                                class="custom-control-success"
                                switch
                                inline
                            />
                        </b-td>
                        <b-td>
                            <b-form-checkbox
                                class="custom-control-danger"
                                 v-model="form.delete"
                                switch
                                inline
                            />
                        </b-td>
                        <b-td>
                            <b-link v-b-tooltip.hover title="Добавить" class="mr-1" @click="addUserPermissions">
                                <feather-icon icon="CheckIcon" class="text-success" size="22" />
                            </b-link>
                            <b-link @click="isOnModuleAddState = false" v-b-tooltip.hover title="Отменить" >
                                <feather-icon icon="XIcon" class="text-danger" size="22" />
                            </b-link>
                        </b-td>
                    </b-tr>
                    <b-tr v-else-if="modules.length > 0" >
                        <b-td colspan="6">
                            <b-link @click="isOnModuleAddState = true">
                                Добавить модуль и права
                            </b-link>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            
        </b-card>
            
    </div>
</template>

<script>

    import App from "@/modules/contents/app"

    export default {

        data() {
            return {
                App,
                form: {
                    module: null,
                    read: false,
                    write: false,
                    edit: false,
                    delete: false
                },
                isOnModuleAddState: false,
            }
        },
        props: {
            user: Object
        },
        methods: {
            
            delperms( module, index ) {
                this.$request.post("users/update", {
                    action: 'delperms',
                    user: this.$user.id,
                    module: module
                }).then( rsp => {
                    this.user.permissions.splice(index, 1)
                });
            },
            
            setperms( val, module, key ) {
                this.$request.post("users/update", {
                    action: 'setperms',
                    user: this.user.id,
                    module: module,
                    permission: key,
                    value: val ? 1 : 0
                });
            },
            
            addUserPermissions( args ) {
                
                if( !this.form.module ) return;
                
                this.$request.post("users/update", {
                    action: 'addperms',
                    user: this.user.id,
                    module: this.form.module,
                    read: this.form.read ? 1 : 0,
                    write: this.form.write ? 1 : 0,
                    edit: this.form.edit ? 1 : 0,
                    delete: this.form.delete ? 1 : 0,
                }).then( permissions => {
                    
                    this.user.permissions = permissions;
                    this.isOnModuleAddState = false;
                    
                    this.form = {
                        module: null,
                        read: false,
                        write: false,
                        edit: false,
                        delete: false
                    };
                    
                });
            },
        },
        computed: {
            modules() {
                let active = this.user.permissions.map(item => item.registry.id);
                return this.App.modules.filter( item => active.indexOf(item.id) < 0 );
            },
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>